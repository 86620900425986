import { render, staticRenderFns } from "./ShowV2.vue?vue&type=template&id=4d52070a&scoped=true&"
import script from "./ShowV2.vue?vue&type=script&lang=js&"
export * from "./ShowV2.vue?vue&type=script&lang=js&"
import style0 from "./ShowV2.vue?vue&type=style&index=0&id=4d52070a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d52070a",
  null
  
)

export default component.exports